
var fb_config = {
  apiKey: "AIzaSyD2-wmpeQLRB5pUd_SH5xv-tZRIK1Y_FUY",
  authDomain: "tpa-show-2020.firebaseapp.com",
  databaseURL: "https://tpa-show-2020.firebaseio.com",
  projectId: "tpa-show-2020",
  storageBucket: "tpa-show-2020.appspot.com",
  messagingSenderId: "401825426363",
  appId: "1:401825426363:web:b8000f6a01ed756d7cb29e",
  measurementId: "G-1ZTRNB93FW"
};

export default fb_config 
